<template>
  <div class="container text-center relative">
    <svg-icon class="mt-64 mb-24"
              type="iconwancheng"
              style="font-size: 66px; color: #00DE45;" />
    <p class="mb-16 text-24 text-color-333 font-medium">直播课程创建完成</p>
    <p class="mb-24 text-16 text-color-666">您还可以进行更多的直播互动、运营相关设置</p>
    <div class="mb-24">
      <router-link :to="{name: 'liveOpenClass_statistic', query: {id: $route.query.id}}">
        <a-button class="mr-80 bg-color-f text-admin-primary border-admin-primary">更多设置</a-button>
      </router-link>

      <router-link :to="{name: 'live_enter', query: {id: $route.query.id}}"
                   target="_blank">
        <a-button type="primary"
                  :disabled="!hasTeacher">去直播</a-button>
      </router-link>

    </div>
    <div class="flex justify-between items-center mx-auto p-8  border border-radius"
         style="width: fit-content; background: #EDF7FF;">
      <Qrcode :width="120"
              :height="120"
              :value="liveEntryUrl"
              :dotsOptions="{type: 'square', color: '#000'}"
              :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              imgclass="download-img" />
      <p class="ml-8 text-right">
        打开微信扫码<br>立即查看课程
      </p>
    </div>

    <router-link :to="{path: '/marketingUtil/liveColumnStatistic', query: {setId: $route.query.setId}}">
      <div v-if="Number($route.query.setId)"
           class="absolute right-24 top-20 text-admin-primary text-16">
        <svg-icon class="mr-4"
                  type="iconfanhui" />
        返回专栏
      </div>
    </router-link>
  </div>

</template>

<script>
import { computed, defineComponent } from "vue";
import Qrcode from "qrcode-vue3";

import SvgIcon from "@/components/SvgIcon";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "LiveOpenClassCreateMiddle",

  components: {
    SvgIcon,
    Qrcode,
  },

  setup() {
    const routerHeaderInfo = [
      {
        path: "/marketingUtil/liveOpenClass",
        name: "直播公开课",
      },
      { name: "新建直播" },
    ];

    const route = new useRoute();
    const liveEntryUrl =
      "https://" +
      document.domain +
      "/customer-h5/livePlay?externalTicket=" +
      route.query.externalTicket +
      "&corpId=" +
      route.query.corpId;

    const hasTeacher = computed(() => {
      return Number(route.query.teacherId);
    });

    return {
      routerHeaderInfo,
      liveEntryUrl,
      hasTeacher,
    };
  },
});
</script>
<style lang='less' scoped>
.container {
  margin: 0 122px;
  margin-top: 5%;
  width: auto;
  height: 480px;
  background-color: fade(@color-primary, 10);
  border-radius: 24px;
}
</style>